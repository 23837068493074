<template>
    <div class="goods-page">
        <div class="goods-checklist">
            <van-collapse v-model="collapseActive" accordion>
                <van-collapse-item
                    v-for="(item, index) in dataList"
                    :name="index"
                    :title="item.production"
                    class="checklist-item"
                >
                    <p>资产序列号：{{ item.assets }}</p>
                    <p>终端序列号:{{ item.equipment }}</p>
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { GET_GOODS_SEND_POLE_DETAIL } from "@/api/goods";
import usePublic from "@/hooks/usePublic";

let { $route, $router, $store } = usePublic();

onMounted(() => {
    getDetail();
});

let collapseActive = ref(null);

// 获取详情
let dataList = ref([]);

let getDetail = () => {
    GET_GOODS_SEND_POLE_DETAIL({ id: $route.params.id }).then((cres) => {
        console.log(cres.data.data);
        dataList.value = cres.data.data;
    });
};
</script>

<style lang="scss" scoped>
.goods-page {
    min-height: 100vh;
    padding-bottom: 60px;
    background: #f0f0f0;

    .goods-information {
        min-height: 20vh;
        background: #fff;
        font-size: 12px;
        display: flex;
        flex-direction: column;

        div {
            margin-top: 20px;
            padding: 0 15px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            color: #000;

            p:nth-child(2) {
                color: #999999;
            }
        }

        div:nth-child(4) {
            flex-direction: column;
            margin-bottom: 20px;

            p:nth-child(2) {
                padding-top: 10px;
            }
        }

        div:nth-child(1) {
            p:nth-child(2) {
                color: #df5f0b;
            }
        }
    }

    .goods-checklist {
        min-height: 10vh;
        margin-top: 10px;
        background: #fff;
        padding: 20px 0px;

        .checklist-item {
            p {
                margin: 10px 0;
            }
        }
    }

    .confirm-button {
        position: fixed;
        bottom: 0;
    }
}
</style>
